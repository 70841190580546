import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionTeam from '../components/organisms/SectionTeam'
import SectionMediaMentions from '../components/organisms/SectionMediaMentions'
import ActionBar from '../components/molecules/ActionBar'

export default function PageGatbsyAbout(props: PageProps) {
  const content = (props.data as any).content.edges[0].node.childPagesJson
  const { title, description, actions } = content

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
    >
      <SectionMediaMentions />
      <SectionTeam />
      <ActionBar actions={actions} />
    </Layout>
  )
}

export const contentQuery = graphql`
  query AboutPageQuery {
    content: allFile(filter: { relativePath: { eq: "pages/about.json" } }) {
      edges {
        node {
          childPagesJson {
            title
            description
            actions {
              title
              description
              url
            }
          }
        }
      }
    }
  }
`
