import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import shortid from 'shortid'
import Section from '../molecules/Section'
import Member, { MemberItem } from '../molecules/Member'
import styles from './SectionTeam.module.css'

const query = graphql`
  query SectionTeamQuery {
    content: teamJson {
      description
      title
      advisors {
        description
        title
      }
      core {
        description
        title
      }
    }

    core: allTeamCoreJson {
      edges {
        node {
          github
          linkedin
          name
          twitter
          website
          image {
            childImageSharp {
              ...MemberPhoto
            }
          }
        }
      }
    }

    advisors: allTeamAdvisorsJson {
      edges {
        node {
          linkedin
          name
          twitter
          website
          position
          quote
          image {
            childImageSharp {
              ...MemberPhoto
            }
          }
        }
      }
    }
  }
`

const GroupHeader = ({
  title,
  description
}: {
  title: string
  description: string
}) => (
  <div className={styles.header}>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
)

export default function SectionTeam() {
  const data = useStaticQuery(query)
  const { title, description, advisors, core } = data.content
  const coreList = data.core.edges
  const advisorsList = data.advisors.edges

  return (
    <Section title={title} description={description} headerCenter>
      <GroupHeader title={core.title} description={core.description} />

      <div className={styles.grid}>
        {coreList.map(({ node }: { node: MemberItem }) => (
          <Member member={node} key={shortid.generate()} />
        ))}
      </div>

      <GroupHeader title={advisors.title} description={advisors.description} />

      <div className={styles.grid2}>
        {advisorsList.map(({ node }: { node: MemberItem }) => (
          <Member member={node} key={shortid.generate()} />
        ))}
      </div>
    </Section>
  )
}
